<template>
  <el-dialog
    v-dialogDrag
    title="补入库仓单"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="持仓ID" prop="holdId">
              <el-input v-model="form.holdId" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-for="(item, index) in colList" :key="index" :span="12">
            <el-form-item :label="item.name">
              <el-input disabled :value="item.value"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="firmName">
              <el-input v-model="form.firmName"></el-input>
            </el-form-item>
          </el-col>

          <!-- </el-row> -->
          <!-- <el-row :gutter="20"> -->
          <el-col :span="12">
            <el-form-item label="联系电话" prop="firmPhone">
              <el-input v-model="form.firmPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产日期" prop="outWarehousDay">
              <el-date-picker
                v-model="form.outWarehousDay"
                class="width-100"
                type="date"
                value-format="timestamp"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- </el-row> -->
          <!-- <el-row :gutter="20"> -->
          <!-- <el-col :span="12">
            <el-form-item label="补入方式类型" prop="fillType">
              <el-select class="width-100" v-model="form.fillType">
                <el-option
                  v-for="item in fillTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="重量" prop="weight">
              <el-input v-model="form.weight" :placeholder="'距预售申请量可补入库:'+maxWeight">
                <template slot="append">{{ unit }}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- </el-row> -->
          <!-- <el-row :gutter="20"> -->
          <!-- <el-col :span="12">
            <el-form-item label="仓库" prop="storageId">
              <el-select class="width-100" v-model="form.storageId">
                <el-option
                  v-for="item in storageIdOptions"
                  :key="item.storageId"
                  :label="item.name"
                  :value="item.storageId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>-->
          <el-col :span="12">
            <el-form-item label="预计入库时间" prop="putWarehousTime" label-width="120px">
              <!-- <el-tooltip effect="dark" content="入库后检测需要2-3天" placement="top-end"> -->
              <el-date-picker
                v-model="form.putWarehousTime"
                class="width-100"
                type="date"
                :picker-options="putWarehousTimePicker"
              ></el-date-picker>
              <!-- </el-tooltip> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注(批次)" prop="note">
              <el-input v-model="form.note"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" :loading="isAddLoading" @click="confirmAddProduct()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
/* global  protocolFwd http util*/
export default {
  data() {
    var checkPutHoldWeight = (rule, value, callback) => {
      const reg = /^\d+(\.\d{0,2})?$/
      const that = this
      if (value == '') {
        return callback(new Error('请输入重量'))
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error('重量必须是正数且不能超过两位小数'))
      } else if (Number(value) > that.realMaxWeight) {
        return callback(new Error('重量不得超过申请重量'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      storageIdOptions: [],
      fillTypeOptions: [
        { value: 'T', label: '部分' },
        { value: 'F', label: '全部' }
      ],
      unit: '',
      colList: [],
      form: {
        type: null, // 0:申请入库 1:补入库
        batch: null, // 批次
        firmName: null, // 联系人
        firmPhone: null, // 联系电话
        outWarehousDay: null, // 生产日期
        weight: null,
        applyHoldId: null, // 申请入库id(修改时传)
        holdId: null, // 补入
        note: null, // 申请，补入
        storageId: null,
        fillType: 'T',
        putWarehousTime: null
      },
      rules: {
        firmName: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        firmPhone: [
          { required: true, message: '请输入联系电话', trigger: 'change' }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ],
        outWarehousDay: [
          { required: true, message: '请输入生产日期', trigger: 'change' }
        ],
        holdId: [
          { required: true, message: '请输入持仓ID', trigger: 'change' }
        ],
        // storageId: [
        //   { required: true, message: "请选择仓库", trigger: "change" }
        // ],
        weight: [
          { required: true, message: '请输入补入库重量', trigger: 'change' },
          {
            validator: checkPutHoldWeight,
            trigger: 'change'
          }
        ],
        note: [{ required: true, message: '请输入备注', trigger: 'change' }],
        fillType: [
          { required: true, message: '请选择补入类型', trigger: 'change' }
        ],
        putWarehousTime: [
          { required: true, message: '请选择预计入库时间', trigger: 'change' }
          // { validator: checkPutWarehousDay, trigger: "blur" }
        ]
      },
      putWarehousTimePicker: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      details: {},
      isAddLoading: false,
      maxWeight: 0,
      realMaxWeight: 0
    }
  },
  watch: {
    'form.fillType': {
      handler(val, oldVal) {
        if (val == 'F') {
          this.form.weight = this.maxWeight
        }
      }
    }
  },
  methods: {
    showDialog(row) {
      this.colList = []
      if (row.specificationsName) {
        const str = JSON.parse(row.specificationsName)
        console.log(str)
        for (const key in str) {
          const obj = {}
          obj.name = key
          obj.value = str[key]
          this.colList.push(obj)
        }
      }
      if (row.manufacturer) {
        const obj = {}
        obj.name = '生产厂家'
        obj.value = row.manufacturer
        this.colList.push(obj)
      }
      if (row.placeOrigin) {
        const obj = {}
        obj.name = '产地'
        obj.value = row.placeOrigin
        this.colList.push(obj)
      }
      if (row.brand) {
        const obj = {}
        obj.name = '品牌'
        obj.value = row.brand
        this.colList.push(obj)
      }
      this.dialogVisible = true
      this.getDetails(row.holdId)
      this.maxWeight = util.floatSub(row.putHoldWeight, row.realHoldWeight)
      this.realMaxWeight = row.putHoldWeight
      this.unit = row.unit
      this.getStorageIdOptions()
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then((response) => {
        const { code, message, value } = response.data
        if (code == 0) {
          this.storageIdOptions = value
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    },
    getDetails(holdId) {
      this.form.holdId = holdId
      // protocolFwd.param_queryResourcesOrderDetails.param.pickOrderId = id;
      // http
      //   .postFront(protocolFwd.param_queryResourcesOrderDetails)
      //   .then(response => {
      //     const { code, message, value } = response.data;
      //     if (code == 0) {
      //       this.details = value;
      //       this.form.orderId = value.orderId;
      //       for (let i in this.details) {
      //         if (!this.details[i]) {
      //           this.details[i] = "--";
      //         }
      //       }
      //       if (this.details.putWarehousDay) {
      //         this.details.putWarehousDay = this.details.putWarehousDay.toString().slice(0, 4) + '-' + this.details.putWarehousDay.toString().slice(4, 6) + '-' + this.details.putWarehousDay.toString().slice(6, 8);
      //       }
      //     } else {
      //       this.$EL_MESSAGE(message);
      //     }
      //   });
    },
    confirmAddProduct() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          protocolFwd.param_fillApplyWarehous.param = this.form
          protocolFwd.param_fillApplyWarehous.param.type = 1
          this.isAddLoading = true
          http
            .postFront(protocolFwd.param_fillApplyWarehous)
            .then((response) => {
              const { code, message } = response.data
              this.isAddLoading = false
              if (code == 0) {
                this.dialogVisible = false
                this.$EL_MESSAGE('提交成功！待后台审核成功后方可补入库')
                this.$refs['form'].resetFields()
                this.$emit('reLoad')
                this.handleClose()
              } else {
                this.$EL_MESSAGE(message)
              }
            })
            .catch(() => {
              this.isAddLoading = false
            })
        }
      })
    },
    handleClose() {
      this.form = {
        type: null, // 0:申请入库 1:补入库
        batch: null, // 批次
        firmName: null, // 联系人
        firmPhone: null, // 联系电话
        weight: null,
        outWarehousDay: null, // 生产日期
        applyHoldId: null, // 申请入库id(修改时传)
        holdId: null, // 补入
        note: null, // 申请，补入
        storageId: null,
        fillType: null,
        putWarehousTime: null
      }
      this.unit = ''
      this.maxWeight = 0
      this.realMaxWeight = 0
      this.$nextTick(() => {
        this.$refs['form'].clearValidate()
      })
      this.dialogVisible = false
    }
  }
}
</script>
